<template>
  <div class="sampleList">
    <van-nav-bar
      title="报检列表"
      left-text="返回"
      right-text="筛选"
      left-arrow
      @click-left="back"
      @click-right="chooseshow = true"
    />
    <van-row type="flex" justify="space-between" class="range mt10">
      <van-col span="12"></van-col>
      <van-col span="12" class="tr">
        <span class="fs12">{{isOwn.txt}}</span>
        <van-switch class="ml5"
        :value="isOwn.status"
        :loading="false" 
        size="18px"
        @change="isOwnChange"/>
      </van-col>
    </van-row>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        :immediate-check="true"
        :offset="420"
        error-text="请求失败，点击重新加载"
        @load="onLoad"
      >
        <van-cell-group class="mt10 van-clearfix" v-for="(item, idx) in list" :key="idx">
          <van-cell class="sampleitem" v-if="item">
            <template #title>
              <div v-if="Number(item.inspect_status) > 2">
                <van-icon class="status" v-if="item.check.conclusion === '1'" class-prefix="icon-font" name="hege" color="#07c160" size="42"/>
                <van-icon class="status" v-else class-prefix="icon-font" name="buhege" color="#ee0a24" size="42"/>
              </div>
              <div v-else>
                <van-icon class="status" v-if="item.is_cancel === '1'" class-prefix="icon-font" name="zf" color="#4d4d4d" size="42"/>
              </div>
              <van-row class="border-b" type="flex" justify="space-between">
                <van-col span="12"><span>{{item.inspect_code + '-' + item.check.id}}</span></van-col>
                <van-col span="12" class="tr"><span>{{item.inspect_time}}</span></van-col>
              </van-row>
              <van-row type="flex" justify="space-between" class="mt6">
                <van-col span="18"><span class="custom-title">{{item.sample_name + ' ' + item.sample_specs}}</span></van-col>
                <van-col span="6" class="tr">
                  <van-tag v-if="item.inspect_status === '0'" round size="medium" type="warning">待检测</van-tag>
                  <van-tag v-if="item.inspect_status === '1'" round size="medium" type="success">检测中</van-tag>
                  <van-tag v-if="item.inspect_status === '2'" round size="medium" type="primary">初检完成</van-tag>
                  <van-tag v-if="item.inspect_status === '3'" round size="medium" type="primary">检测完成</van-tag>
                  <van-tag v-if="item.inspect_status === '4'" round size="medium" type="success">审批中</van-tag>
                  <van-tag v-if="item.inspect_status === '5'" round size="medium" type="primary">审批完成</van-tag>
                </van-col>
              </van-row>
              <van-row type="flex" justify="space-between" v-if="item.supplier_name" class="mt6">
                <van-col span="24" class="gray-7">供应商：{{item.supplier_name}}</van-col>
              </van-row>
              <van-row type="flex" justify="space-between" v-if="item.inspect_emp_name" class="mt6">
                <van-col span="24" class="gray-7">报检人：{{item.inspect_emp_name}}[{{item.inspect_dep_name}}]</van-col>
              </van-row>
              <van-row type="flex" justify="space-between" v-if="item.car_number" class="mt6">
                <van-col span="24" class="gray-7">车辆信息：{{item.car_number}}</van-col>
              </van-row>
              <van-row type="flex" justify="space-between" v-if="item.inspect_emp_name" class="mt6">
                <van-col span="12" class="gray-7">标签打印：
                  <van-tag v-if="item.is_print === '1'" plain round color="#999999">已打印</van-tag>
                  <van-tag v-else plain round type="success">未打印</van-tag>
                </van-col>
                <van-col span="12" class="gray-7 tr">ERP：
                  <span class="erpTip">
                    <span class="fs12">{{item.is_fill_txt}}</span>
                    <van-switch class="ml5" active-value="1" inactive-value="0"
                    :value="item.is_fill"
                    :loading="false" 
                    size="18px"
                    @change="isERPChange(idx)"/>
                  </span>
                </van-col>
              </van-row>
              <van-row class="border-t" type="flex" justify="end">
                <van-button class="ml5"
                  plain 
                  hairline 
                  round 
                  type="info" 
                  size="small" 
                  @click="toUrl('/sampledetail', item.id)" v-track="{triggerType:'click', title:'报检详情',currentUrl: $route.path,behavior: item.inspect_code, duration: new Date().getTime()}">详情</van-button>
                <van-button class="ml5" v-if="item.inspect_status === '3' && item.check.conclusion === '0'"
                  plain 
                  hairline 
                  round 
                  type="info" 
                  size="small"
                  @click="apply(idx)">让步申请</van-button>
                <van-button class="ml5" v-if="item.is_cancel !== '1'"
                  plain 
                  hairline 
                  round 
                  type="info" 
                  size="small"
                  @click="toUrl('/sample', item.id, '1')">复制新建</van-button>
                <van-button class="ml5" v-if="item.is_cancel !== '1'"
                  plain 
                  hairline 
                  round 
                  type="info" 
                  size="small"
                  @click="showQRcode(idx)">打印标签</van-button>
                <van-button class="ml5" v-if="item.inspect_status === '0' && item.inspect_emp_id === user.dd_data.dd_user_id && item.is_cancel !== '1'"
                  plain 
                  hairline
                  round
                  type="info" 
                  size="small"
                  @click="toUrl('/sample', item.id, '0')">修改</van-button>
                <van-button class="ml5" v-if="item.inspect_status === '0' && item.is_cancel === '0' && item.inspect_emp_id === user.dd_data.dd_user_id"
                  plain 
                  hairline 
                  round
                  type="default"
                  size="small"
                  @click="Del(idx)">作废</van-button>
                <van-button class="ml5" v-if="item.inspect_status === '0' && item.is_cancel === '1'"
                  plain 
                  hairline 
                  round
                  type="info" 
                  size="small"
                  @click="reDel(idx)" v-track="{triggerType:'click', title:'恢复',currentUrl: $route.path,behavior: item.inspect_code, duration: new Date().getTime()}">恢复</van-button>
              </van-row>
            </template>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>

<!-- 让步申请 弹窗  -->
    <van-dialog v-model="applyshow" title="让步申请" :showConfirmButton="false">
      <van-form @submit="applySubmit">
        <van-field
          readonly
          clickable
          required
          name="inspect_emp_id"
          :value="approvalParams.approval_emp_name"
          label="审批人"
          placeholder="点击选择审批人"
          @click="showApproval"
          :rules="[{ required: true, message: '请选择审批人' }]"
        />
        <van-field type="textarea" v-model="approvalParams.concession_acceptance_reason"
          autosize
          required
          rows="2"
          show-word-limit
          maxlength="120"
          label=""
          placeholder="申请理由" 
          :rules="[{ required: true, message: '请填写申请理由' }]"
        />
        <van-row gutter="20" class="btgroup" type="flex" justify="center">
          <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="applyshow = false">取消</van-button></van-col>
          <van-col span="16"><van-button round block size="small" type="primary" native-type="submit" v-track="{triggerType:'click', title:'让步申请',currentUrl: $route.path,behavior: this, duration: new Date().getTime()}">提交</van-button></van-col>
        </van-row>
      </van-form>
    </van-dialog>
  
  <!-- 数据筛选 弹窗 -->
    <van-popup round v-model="chooseshow" position="top">
      <van-form @submit="choose">
        <van-field :value="params.start_time"
          name="start_time"
          label="开始时间"
          placeholder="选择开始时间"
          readonly
          clickable
          @click="StimeShow" />
        <van-field :value="params.end_time"
          name="end_time"
          label="结束时间"
          placeholder="选择结束时间"
          readonly
          clickable
          @click="EtimeShow" />
        <van-field v-model="params.sample_name"
          name="sample_name"
          label="样品名称" 
          clearable />
        <van-field :value="params.type_name"
          name="inspect_type"
          label="报检类型"
          placeholder="点击选择报检类型"
          readonly
          clearable
          @click-input="showType"/>
        <van-field :value="params.conclusion_name"
          name="conclusion"
          label="是否合格"
          placeholder="点击选择是否合格"
          readonly
          clearable
          @click-input="statusShow = true"/>
        <van-cell title="只看作废">
          <template #right-icon>
            <span>{{params.is_cancel === '0' ? '否' : '是'}}</span>
            <van-switch class="ml5" v-model="params.is_cancel" active-value="1" inactive-value="0" size="18"/>
          </template>
        </van-cell>
        <!-- <van-field
          readonly
          clickable
          name="inspect_emp_id"
          :value="params.inspect_emp_id"
          label="报检人"
          placeholder="点击选择报检人"
          @click="empShow = true"
        />
        <van-field
          readonly
          clickable
          name="inspect_dep_id"
          :value="params.inspect_dep_id"
          label="报检部门"
          placeholder="点击选择报检部门"
          @click="depShow = true"
        /> -->
        <van-row gutter="20" class="btgroup" type="flex" justify="center">
          <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="reSet">重置</van-button></van-col>
          <van-col span="16"><van-button title="筛选" round block size="small" type="primary" native-type="submit">确定</van-button></van-col>
        </van-row>
      </van-form>
    </van-popup>

<!-- 时间日期 弹窗 -->
    <van-popup v-model="timeShow" position="bottom">
      <van-datetime-picker type="date"
        v-model="currentDate"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="timeConfirm"
        @cancel="timeShow = false"
      />
    </van-popup>

<!-- 检测类型 弹窗 -->
    <van-popup v-model="typeShow" position="bottom">
      <van-picker
        show-toolbar
        value-key="type_name"
        :columns="types"
        @confirm="typeConfirm"
        @cancel="typeShow = false"
      />
    </van-popup>

<!-- 是否合格 弹窗 -->
<van-popup v-model="statusShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="statuses"
        @confirm="statusConfirm"
        @cancel="statusShow = false"
      />
    </van-popup>

<!-- 让步申请 审批人 弹窗 -->
    <van-popup v-model="ApprovalStatus" position="bottom" round>
      <van-picker title="审批人选择"
        show-toolbar
        :columns="Approvals"
        value-key="username"
        @confirm="ApprovalConfirm"
        @cancel="ApprovalStatus = false"
      />
    </van-popup>

<!-- 标签纸打印部分 弹窗 -->
    <van-popup v-model="QRcodeShow" position="bottom">
      <div class="label" id="printMe">
        <van-row class="qrlist" type="flex">
          <van-col span="24" class="qr-tt"><p class="tac">{{currentItem.sample_name}}</p></van-col>
          <van-col span="12" class="qr-lt">
            <p class="fs16">批号：{{currentItem.inspect_batch}}</p>
            <p v-if="currentItem.inspect_num">数量：{{currentItem.inspect_num}}</p>
            <p>报检：{{currentItem.inspect_emp_name}}</p>
            <p>日期：{{currentItem.inspect_time}}</p>
            <p v-if="currentItem.car_number">车牌号：{{currentItem.car_number}}</p>
            <p v-if="currentItem.other_remark">说明：{{currentItem.other_remark}}</p>
            <p v-if="currentItem.contract_no">其他：{{currentItem.contract_no}}</p>
          </van-col>
          <van-col span="12" class="qr-rt">
            <QRcode :width="code.with" :height="code.height" :content="code.content" :load="false"></QRcode>
            <p class="tc">ID：{{currentItem.check.id}}</p>
          </van-col>
          <van-col v-if="currentItem.supplier_name" span="24" class="qr-bt"><p>供应商：{{currentItem.supplier_name}}</p></van-col>
        </van-row>
      </div>
      <van-button class="printBtn" round plain type="default" v-print="print" v-track="{triggerType:'click', title:'打印报检标签',currentUrl: $route.path,behavior: currentItem.inspect_code, duration: new Date().getTime()}">立即打印</van-button>
    </van-popup>
  </div>
</template>
<script>
import Vue from 'vue'
import { List, Cell, CellGroup, Tag, Col, Row, Button, Dialog, Form, Field, NavBar, Popup, DatetimePicker, Picker, Icon, Switch, PullRefresh } from 'vant'
import { mapActions } from 'vuex'

Vue.use(List)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Tag)
Vue.use(Col)
Vue.use(Row)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(Field)
Vue.use(NavBar)
Vue.use(Popup)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Switch)
Vue.use(PullRefresh)

export default {
  data() {
    const that = this
    return {
      idx: '',
      user: { },
      list: [ ],
      page: {
        page: 1,
        offset: 10
      },
      params: {
        start_time: this.$common.formatDate(new Date(new Date().setDate((new Date().getDate()-7)))),
        end_time: this.$common.formatDate(new Date()),
        sample_name: '',
        inspect_type: '',
        inspect_dep_id: '',
        inspect_emp_id: '',
        conclusion: '',
        conclusion_name: '',
        is_inspect_recheck: '',
        is_cancel: '0'
      },
      approvalParams: {
        id: '',
        approval_emp_id: '',
        approval_emp_name: '',
        concession_acceptance_reason: ''
      },
      print: {
        id: 'printMe',
        popTitle: '打印标签', // 打印配置页上方的标题
        extraHead: '', // 最上方的头部文字，附加在head标签上的额外标签，使用逗号分割
        preview: false, // 是否启动预览模式，默认是false
        previewTitle: '打印预览', // 打印预览的标题
        previewPrintBtnLabel: '预览结束，开始打印', // 打印预览的标题下方的按钮文本，点击可进入打印
        zIndex: 999, // 预览窗口的z-index，默认是20002，最好比默认值更高
        standard: '',
        extarCss: '',
        openCallback() { 
          const obj = that.list[that.idx]
          that.$post('inspect/print-record', 'api', { id: obj.id }).then(res => {
            if (res.data) {
              that.$set(obj, 'is_print', '1' )
            } else {
              that.$notify({ type: 'warning', message: res.message })
            }
          })
          // console.log('执行打印了！') 
        }, // 调用打印时的callback
      },
      currentItem: {
        check: { }
      },
      code: {
        with: '120',
        height: '120',
        content: ''
      },
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      applyForm: '',
      types: [],
      statuses: [{ 
        text: '全部', value: ''
      }, { 
        text: '合格', value: '1'
      }, { 
        text: '不合格', value: '0'
      }],
      Approvals: [],
      emps: [],
      deps: [],
      isOwn: {
        status: true,
        txt: '个人'
      },
      time:'',
      error: false,
      loading: false,
      finished: false,
      refreshing: false,
      chooseshow: false,
      applyshow: false,
      timeShow: false,
      typeShow: false,
      statusShow: false,
      empShow: false,
      depShow: false,
      QRcodeShow: false,
      ApprovalStatus: false
    }
  },
  created: function () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      this.user = user
      this.params.inspect_emp_id = user.dd_data.dd_user_id
    } else {
      this.asyncUser().then(res => {
        this.user = res
        this.params.inspect_emp_id = res.dd_data.dd_user_id
      })
    }
    if (localStorage.getItem('isOwn')) {
      this.isOwn = JSON.parse(localStorage.getItem('isOwn'))
      this.params.inspect_emp_id = this.isOwn.inspect_emp_id
    }
    localStorage.setItem('page', JSON.stringify(this.page))
    localStorage.setItem('params', JSON.stringify(this.params))
  },
  computed:{ },
  methods: {
    ...mapActions('listData', ['asyncsetSampleType']),
    ...mapActions('router', ['asyncUser']),
    onLoad() {
      if (this.refreshing) {
        this.list = []
        this.page.page = 1
        this.refreshing = false
      }
      this.$get('inspect/get-inspect-list', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        const data = res.data.list
        data.forEach((res) => {
          res.inspect_time = this.$common.formatDate(new Date(1000 * res.inspect_time))
          res.is_fill_txt = res.is_fill === '0' ? '未填' : '已填'
        })
        this.count = res.data.count
        this.list = this.list.concat(data) // 追加数据
        this.loading = false // 加载状态结束
        if (this.list.length < res.data.count) { // 数据全部加载完成
          this.page.page++
        } else {
          this.finished = true
        }
      }).catch(err => {
        this.list = []
        if (err.code === -2) {
          this.finished = true
        } else {
          this.error = true
        }
        this.loading = false
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true
      this.onLoad()
    },
    apply(idx) {
      this.idx = idx
      this.applyshow = true
      this.approvalParams = {}
      for (let key in this.approvalParams) {
        this.approvalParams[key] = ''
      }
    },
    Del(idx) {   
      const that = this      
      Dialog.alert({
        title: '作废',
        message: '您确定要作废此条报检吗？',
        theme: 'round-button',
        showCancelButton: true        
      }).then(() => {
        that.$post('inspect/cancel-or-recovery', 'api', {
          id: that.list[idx].id,
          is_cancel: 1
        }).then(res => {
          that.$notify({ type: 'success', message: res.message })
          that.list.splice(idx, 1)
        })
      }).catch(() => {
        Dialog.close
      })
    },
    reDel(idx) {
      this.$post('inspect/cancel-or-recovery', 'api', {
        id: this.list[idx].id,
        is_cancel: 0
      }).then(res => {
        // this.$notify({ type: 'success', message: res.message })
        this.list.splice(idx, 1)
        Dialog.alert({
          message: res.message,
          theme: 'round-button',
          showCancelButton: true,
          confirmButtonText: '返回列表',
          cancelButtonText: '留在此页'
        }).then(() => {
          this.$set(this.params, 'is_cancel', '0')
          this.choose()
        }).catch(() => {
          Dialog.close
        })
      })
    },
    applySubmit() {
      this.approvalParams.id = this.list[this.idx].id
      this.$post('inspect/apply-compromise-receive', 'api', this.approvalParams).then(res => {
        this.$notify({ type: 'success', message: res.message })
        this.applyshow = false
        this.list[this.idx].inspect_status = '4'
      }).catch(err => {
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    back() {
      this.$router.push({ path: '/home' })
      // this.$router.go(-1)
    },
    choose() {
      this.page = JSON.parse(localStorage.getItem('page'))
      this.finished = false
      this.loading = true
      this.list = []
      this.onLoad()
      this.chooseshow = false
    },
    reSet() {
      this.params = JSON.parse(localStorage.getItem('params'))
    },
    showType() {
      this.typeShow = true
      this.getTypes()
    },
    showApproval() {
      this.ApprovalStatus = true
      this.getApproval()
    },
    StimeShow() {
      this.timeShow = true
      this.time = 'stime'
      this.currentDate = new Date(this.params.start_time)
    },
    EtimeShow() {
      this.timeShow = true
      this.time = 'etime'
      this.currentDate = new Date(this.params.end_time)
    },
    timeConfirm(time) {
      if (this.time === 'stime') {
        this.params.start_time = this.$common.formatDate(time)
      } else if (this.time === 'etime') {
        this.params.end_time = this.$common.formatDate(time)
      }
      this.timeShow = false
    },
    typeConfirm(value) {
      this.params.type_name = value.type_name
      this.params.inspect_type = value.id
      this.typeShow = false
    },
    statusConfirm(e) {
      this.params.conclusion = e.value
      this.params.conclusion_name = e.text
      this.statusShow = false
    },
    getTypes() {
      const that = this
      let SampleType = this.$store.state.listData.SampleType
      if (SampleType.length === 0) {
        that.$get('sample-type/get-sample-type-list', 'api', {
        page: 1,
        offset: 100,
        }).then(res => {
          const datas = res.data.list
          that.types = datas
          that.asyncsetSampleType(datas)
        }, err => {
          that.types = []
          this.$notify({ type: 'warning', message: err.message })
        })
      } else {
        that.types = SampleType
      }
      this.typeStatus = true
    },
    getApproval() {
      this.$get('user/get-user-list', 'api', {
        page: 1,
        offset: 100,
        is_accept_auth: 1
      }).then(res => {
        const datas = res.data.data.filter(ref => ref.uid !== this.user.dd_data.dd_user_id)
        this.Approvals = datas
      }, err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    ApprovalConfirm(val) {
      this.approvalParams.approval_emp_id = val.uid
      this.approvalParams.approval_emp_name = val.username
      this.ApprovalStatus = false
    },
    showQRcode(idx) {      
      // if (this.$common.isMobilePhone()) {        
      //   Dialog({ message: '请使用PC端打印' })
      //   return false
      // }
      this.idx = idx
      this.QRcodeShow = true
      this.currentItem = this.list[idx]
      this.code.content = 'http://lims.cicg.tech/#/analysisdetail?type=1&id=' + this.list[idx].check.id
      // let params = {
      //   userId: '123', //用户id
      //   userName: 'Bob', //用户名
      //   userNumber: 'sas123', //工号 
      // }
      // this.content = JSON.stringify(params)
    },
    isOwnChange(val) {
      this.$set(this.isOwn, 'status', val)
      this.page = JSON.parse(localStorage.getItem('page'))
      this.finished = false
      this.loading = true
      this.list = []
      if (val) {
        this.$set(this.params, 'inspect_emp_id', this.user.dd_data.dd_user_id)
        this.$set(this.isOwn, 'txt', '个人')
        this.$set(this.isOwn, 'inspect_emp_id', this.user.dd_data.dd_user_id)
      } else {
        this.$set(this.params, 'inspect_emp_id', '')
        this.$set(this.isOwn, 'txt', '部门')
        this.$set(this.isOwn, 'inspect_emp_id', '')
      }
      localStorage.setItem('isOwn', JSON.stringify(this.isOwn))
      this.onLoad()
    },
    isERPChange(idx) {
      const that = this
      if (this.list[idx].is_fill === '0') {
        this.$post('inspect/do-fill', 'api', {
          id: this.list[idx].id
        }).then(res => {
          that.$set(this.list[idx], 'is_fill', '1')
        }).catch(err => {
          this.$notify({ type: 'warning', message: err.message })
        })
      }
    },
    toUrl(path, id, type) {
      this.$router.push({ path: path, query: { id: id, type: type } })
    }
  }
}
</script>

<style scoped>
 .status{
  position: absolute;
  width: 42px;
  height: 42px;
  bottom: 5px;
  left: 10px;
  transform:rotate(0);
  -webkit-transform:rotate(0);
  -moz-transform:rotate(0);
 }
 .border-tb{
  border-top: dashed 1px #f1f4f9;
  border-bottom: dashed 1px #f1f4f9;
  padding: 8px 0;
  margin: 8px 0;
 }
 .custom-title{
  font-weight: 700;
 }
 .border-b{
  border-bottom: solid 1px #e6e6e6;
  padding-bottom: 8px;
 }
 .border-t{
  border-top: solid 1px #e6e6e6;
  padding-top: 8px;
  margin-top: 8px;
 }
 .range{
  margin: 0 10px;
 }
 .tr .fs12{
  position: relative;
  top: -5px;
 }
 .label{
  max-width: 480px;
  width: 90%;
  margin: 10px auto;
  border-radius: 6px;
  overflow: hidden;
 }
.label h3{
  text-align: center;
  font-size: 12px;
  line-height: 1.268em;
  margin: 0 auto;
  color: #000000;
}
.qrlist{
  margin: 0;
  padding: 6px 0;
  border-top: solid 2px #999;
  border-bottom: solid 2px #999;
}
.qrlist p{
  letter-spacing: -1px;
  line-height: 1.2em;
  font-size: 12px;
  color: #000000;
  margin: 0;
  padding: 0;
  width: 100%;
  /* word-wrap: break-word;
  word-break: break-all; */
  white-space: nowrap;
}
.qrlist .qr-lt>p.fs16{
  font-size: 16px;
}
.qrlist .qr-bt{
  margin-top: -36px;
  margin-left: -20px;
  transform: scale(0.7, 0.7);
}
.qrlist .qr-lt{
  transform: scale(0.72, 0.72);
  margin-top: -16px;
  margin-left: -10px;
  position: relative;
  z-index: 1;
}
.qrlist .qr-rt{
  transform: scale(0.6, 0.6);
  margin-top: -14px;
  position: relative;
  z-index: 10;
}
.btgroup{
  padding:10px 10px 20px;
}
.printBtn{
  width: 60%;
  margin: 5px 20% 15px;
}
.erpTip{
  position: relative;
  top: 4px;
}
/*去除页眉页脚*/
@page{
  size:  auto;   /* auto is the initial value */
  margin: 3mm;  /* this affects the margin in the printer settings */
}

html{
  background-color: #FFFFFF;
  margin: 0;  /* this affects the margin on the html before sending to printer */
}
/*去除页眉页脚*/
</style>
